<template>
    <div id="index">
        <dv-full-screen-container class="bg">
            <dv-loading v-if="loading&&hasLogin == 'login'">加载中...</dv-loading>
            <div class="host-body" v-if="!loading&&hasLogin == 'login'">
                <!-- 第一行 头部 -->
                <div class="d-flex jc-center">
                    <dv-decoration-3 style="width:33.3%;height:0.45rem;"/>
                    <div class="d-flex jc-center">
                        <dv-decoration-8 :color="['#0d77ea', '#000000']" style="width:2.5rem;height:.625rem;"/>
                        <div class="title">
                            <span class="title-text"><router-link to="/">千河物流园智慧大屏</router-link></span>
                            <dv-decoration-6 :color="['#50e3c2', '#0d77ea']" :reverse="true" class="title-bototm" style="width:3.125rem;height:.1rem;"/>
                        </div>
                        <div class="title">
                            <span class="title-text"><router-link to="/index2">千河物流园智慧大屏</router-link></span>
                            <dv-decoration-6 :color="['#50e3c2', '#0d77ea']" :reverse="true" class="title-bototm" style="width:3.125rem;height:.1rem;"/>
                        </div>
                        <div class="title">
                            <span class="title-text"><router-link to="/index4">绥德物流园智慧大屏</router-link></span>
                            <dv-decoration-6 :color="['#50e3c2', '#0d77ea']" :reverse="true" class="title-bototm" style="width:3.125rem;height:.1rem;"/>
                        </div>
                        <dv-decoration-8 :color="['#0d77ea', '#000000']" :reverse="true" style="width:2.5rem;height:.625rem;"/>
                    </div>
                    <dv-decoration-3 style="width:33.3%;height:0.45rem; transform: rotateY(180deg);"/>
                </div>
                <!-- 第二行 -->
                <div class="d-flex jc-between px-2">
                    <div class="d-flex" style="width: 40%">
                        <div class="react-right ml-4" style="width: 6.25rem; text-align: left;background-color: #0f1325;">
                            <span class="react-before"></span>
                            <span class="text">数据分析</span>
                        </div>
                        <div class="react-right ml-3" style="background-color: #0f1325;">
                            <span class="text colorBlue"><dv-decoration-1 style="width:200px;height:50px;"/></span>
                        </div>
                    </div>
                    <div class="d-flex" style="width: 40%">
                        <div class="react-left mr-3" style="background-color: #0f1325;">
                            <span class="text fw-b">{{dateYear}} {{dateWeek}} {{dateDay}}</span>
                        </div>
                        <div class="react-left mr-4" style="width: 6.25rem; background-color: #0f1325; text-align: right;">
                            <span class="react-after"></span>
                            <span class="text"><dv-decoration-1 style="width:200px;height:50px;"/></span>
                        </div>
                    </div>
                </div>
                <div class="body-box">
                    <!-- 第三行数据 -->
                    <div class="content-box">
                        <div>
                            <dv-border-box-13>
                                <div id="centreLeft1">
                                    <div class="bg-color-black">
                                        <div class="d-flex pt-2 pl-2">
                                            <span style="color:#5cd9e8">
                                              <icon name="chart-bar"></icon>
                                            </span>
                                            <div class="d-flex">
                                                <span class="fs-xl text mx-2">今日实时数据（吨）</span>
                                                <dv-decoration-3 style="width:1.75rem;height:.25rem; position:relative;top:-.0375rem;"/>
                                            </div>
                                        </div>
                                        <div class="d-flex jc-center">
                                            <dv-decoration-9 :dur="10" style="width:2rem;height:2rem;margin-top: 0.3rem"></dv-decoration-9>
                                        </div>
                                        <!-- 4个主要的数据 -->
                                        <div class="bottom-data">
                                            <div :key="index" class="item-box" v-for="(item,index) in shishishuju">
                                                <div class="d-flex">
                                                    <dv-digital-flop :config="item.number"/>
                                                </div>
                                                <p class="text" style="text-align: center;font-size: 13px;">
                                                    {{item.text}}
                                                    <span class="colorYellow">(吨)</span>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </dv-border-box-13>
                        </div>
                        <!--<div>
                            <dv-border-box-12>
                                <centerLeft2/>
                            </dv-border-box-12>
                        </div>-->
                        <!-- 中间 -->
                        <div>
                            <dv-border-box-1>
                                <div id="center">
                                    <div class="up">
                                        <div :key="item.title" class="bg-color-black item" v-for="item in centerData">
                                            <p class="ml-3 colorBlue fw-b" style="font-size: 15px;margin-top: 5px !important;">{{item.title}}</p>
                                            <dv-digital-flop :config="item.number" style="margin-top: 10px !important;"/>
                                        </div>
                                    </div>
                                </div>
                            </dv-border-box-1>
                        </div>
                        <!-- 中间 -->
                        <div>
                            <dv-border-box-13>
                                <div id="centreRight2">
                                    <div class="bg-color-black">
                                        <div class="d-flex pt-2 pl-2">
                                            <span style="color:#5cd9e8">
                                              <icon name="align-left"></icon>
                                            </span>
                                            <span class="fs-xl text mx-2">各供应商吨数统计</span>
                                            <dv-decoration-3 style="width:1.75rem;height:.25rem; position:relative;top:-.0375rem;"/>
                                        </div>
                                        <div class="d-flex jc-center body-box" style=" margin-top: 0;">
                                            <dv-decoration-12 :haloDur="5" :scanDur="10" style="width:1.8rem;height:1.8rem;margin:0.1rem auto;"></dv-decoration-12>
                                            <!-- ---------------------------------------- -->
                                            <!--        <centreRight2Chart1 />-->
                                            <dv-capsule-chart :config="gysData" style="width: 95%;height:2.5rem"/>
                                        </div>
                                    </div>
                                </div>
                            </dv-border-box-13>
                        </div>
                        <!--<div>
                            <dv-border-box-13>
                                <centerRight1/>
                            </dv-border-box-13>
                        </div>-->
                    </div>

                    <!-- 第四行数据 -->
                    <div class="bototm-box">
                        <!--<dv-border-box-11 title="各煤种价格曲线图">-->
                        <!--<bottomLeft/>-->
                        <!--</dv-border-box-11>-->
                        <dv-border-box-11 title="本月公司自调吨数统计">
                            <div id="center">
                                <div class="down">
                                    <div class="ranking bg-color-black">
                                        <span style="color:#5cd9e8">
                                          <icon name="align-left"></icon>
                                        </span>
                                        <span class="fs-xl text mx-2 mb-1">本月实时吨数</span>
                                        <dv-active-ring-chart :config="bingyue" style="width:4rem;height:4rem;margin: 1.5em auto;line-height: 40px;"/>
                                    </div>
                                    <div class="ranking bg-color-black">
                                        <span style="color:#5cd9e8">
                                          <icon name="align-left"></icon>
                                        </span>
                                        <span class="fs-xl text mx-2 mb-1">本年实时吨数</span>
                                        <dv-active-ring-chart :config="bingnian" style="width:4rem;height:4rem;margin: 1.5em auto;line-height: 40px;"/>
                                    </div>
                                </div>
                            </div>
                        </dv-border-box-11>
                        <dv-border-box-11 title="本月采购吨数统计">
                            <div id="bottomRight">
                                <div class="bg-color-black">
                                    <div class="d-flex mt-4 pl-2">
                                        <!--<span style="color:#5cd9e8">
                                          <icon name="chart-area"></icon>
                                        </span>-->
                                        <div class="d-flex">
                                            <!--<span class="fs-xl text mx-2">XXXXXXXXXXXX统计图</span>-->
                                            <div class="decoration2">
                                                <dv-decoration-2 :reverse="true" style="width:5px;height:5.5rem;"/>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <div style="width:5.425rem;height:6rem;float: left;">
                                            <dv-decoration-7 style="width:5.425rem;height:30px;text-align: center;margin:10px 0">&emsp;公司自调吨数&emsp;</dv-decoration-7>
                                            <dv-scroll-ranking-board :config="gyzd" style="width:5.425rem;height:5rem"/>
                                        </div>
                                        <div style="width:5.425rem;height:6rem;float: left;margin-left: 0.4rem;">
                                            <dv-decoration-7 style="width:5.425rem;height:30px;text-align: center;margin:10px 0">&emsp;客户自调吨数&emsp;</dv-decoration-7>
                                            <dv-scroll-ranking-board :config="khzd" style="width:5.425rem;height:5rem"/>
                                        </div>
                                        <!--<BottomRightChart />-->
                                    </div>
                                </div>
                            </div>
                        </dv-border-box-11>
                    </div>
                </div>
            </div>
            <div class="host-body" v-if="!loading&&(hasLogin == 'nologin'||hasLogin == ''||hasLogin == null)">
                <div style="width: 600px;height: 400px;border-radius: 10px;margin: 0 auto;margin-top: 200px;text-align: center;">
                    <br><br>
                    <h2>千河物流园智慧大屏</h2>
                    <br><br>
                    <div class="form-group">
                        <input class="form-control" id="userName" placeholder="请输入用户名" style="width: 50%;margin: 0 auto;" type="text" v-model="userName">
                    </div>
                    <div class="form-group">
                        <input class="form-control" id="password" placeholder="请输入密码" style="width: 50%;margin: 0 auto;" type="password" v-model="password">
                    </div>
                    <br>
                    <button @click="denglu" class="btn btn-info btn-block" style="width: 50%;margin: 0 auto;" type="button">登 录</button>
                </div>
            </div>
        </dv-full-screen-container>
    </div>
</template>

<!-- 最新的 Bootstrap 核心 JavaScript 文件 -->
<script src="https://cdn.jsdelivr.net/npm/bootstrap@3.3.7/dist/js/bootstrap.min.js"></script>
<script>
    import {formatTime} from '../utils/index.js';
    //import centerLeft1 from "./centerLeft1";
    //import centerLeft2 from "./bigdata/centerLeft2";
    //import centerRight1 from "./bigdata/centerRight1";
    //import centerRight2 from "./centerRight2";
    //import center from "./center";
    // import bottomLeft from "./bottomLeft";
    //import bottomRight1 from "./bottomRight1";
    //import bottomRight from "./bottomRight";

    export default {
        data() {
            return {
                userName: '',
                password: '',
                //hasLogin: sessionStorage.getItem("hasLogin"),
                hasLogin: 'login',
                loading: true,
                dateDay: null,
                dateYear: null,
                dateWeek: null,
                weekday: ["周日", "周一", "周二", "周三", "周四", "周五", "周六"],

                shishishuju: [
                    {
                        number: {
                            number: [0],
                            toFixed: 1,
                            content: "{nt}"
                        },
                        text: "今日实时公司自调数据"
                    },
                    {
                        number: {
                            number: [0],
                            toFixed: 1,
                            content: "{nt}"
                        },
                        text: "今日实时客户自调数据"
                    },
                    {
                        number: {
                            number: [0],
                            toFixed: 1,
                            content: "{nt}"
                        },
                        text: "今日实时短倒数据"
                    },
                ],//左上实时数据

                centerData: [
                    {
                        title: "今日公司自调采购已入场（车）",
                        number: {
                            number: [0],
                            content: "{nt}",
                            style: {
                                fontSize: 40
                            },
                            formatter
                        },
                    },
                    {
                        title: "今日公司自调采购已出场（车）",
                        number: {
                            number: [1],
                            content: "{nt}",
                            style: {
                                fontSize: 40
                            },
                            formatter
                        }
                    },
                    {
                        title: "今日公司自调采购未出场（车）",
                        number: {
                            number: [1],
                            content: "{nt}",
                            style: {
                                fontSize: 40
                            },
                            formatter
                        }
                    },
                    {
                        title: "今日客户自调采购已入场（车）",
                        number: {
                            number: [1],
                            content: "{nt}",
                            style: {
                                fontSize: 40
                            },
                            formatter
                        }
                    },
                    {
                        title: "今日客户自调采购已出场（车）",
                        number: {
                            number: [1],
                            content: "{nt}",
                            style: {
                                fontSize: 40
                            },
                            formatter
                        }
                    },
                    {
                        title: "今日客户自调采购未出场（车）",
                        number: {
                            number: [1],
                            content: "{nt}",
                            style: {
                                fontSize: 40
                            },
                            formatter
                        }
                    }
                ],

                gysData: {
                    data: [
                        {
                            name: "1#煤棚",
                            value: 0
                        },
                        {
                            name: "2#煤棚",
                            value: 0
                        },
                        {
                            name: "3#煤棚",
                            value: 0
                        },
                        {
                            name: "4#煤棚",
                            value: 0
                        },
                        {
                            name: "5#煤棚",
                            value: 0
                        }
                    ],
                    showValue: true,
                    unit: "吨",
                    colors: ['#e062ae', '#fb7293', '#e690d1', '#32c5e9', '#96bfff'],
                },

                bingyue: {
                    radius: '80%',
                    activeRadius: '85%',
                    lineWidth: 25,
                    activeTimeGap: 10000,
                    digitalFlopToFixed: 1,
                    digitalFlopUnit: "吨",
                    animationCurve: "easeOutCubic",
                    animationFrame: 100,
                    color: ['#10abe9', '#fb7293', '#96bfff'],
                    data: [
                        {
                            name: '公司自调',
                            value: 1
                        },
                        {
                            name: '客户自调',
                            value: 1
                        },
                    ],
                    digitalFlopStyle: {
                        fontSize: 20,
                    },
                    showOriginValue: true
                },
                bingnian: {
                    radius: '80%',
                    activeRadius: '85%',
                    lineWidth: 25,
                    activeTimeGap: 15000,
                    digitalFlopToFixed: 1,
                    digitalFlopUnit: "吨",
                    animationCurve: "easeOutCubic",
                    animationFrame: 100,
                    color: ['#10abe9', '#fb7293', '#96bfff'],
                    data: [
                        {
                            name: '公司自调',
                            value: 1
                        },
                        {
                            name: '客户自调',
                            value: 1
                        },
                    ],
                    digitalFlopStyle: {
                        fontSize: 20,
                    },
                    showOriginValue: true
                },

                gyzd: {
                    rowNum: 7,
                    valueFormatter({value}) {
                        // console.warn(arguments)
                        const reverseNumber = (value + '').split('').reverse()
                        let valueStr = ''

                        while (reverseNumber.length) {
                            const seg = reverseNumber.splice(0, 3).join('')
                            valueStr += seg
                            if (seg.length === 3) valueStr += ','
                        }

                        return valueStr.split('').reverse().join('') + "吨"
                    },
                    data: [
                        {
                            name: '洗精煤',
                            value: 10000
                        },
                        {
                            name: '末煤',
                            value: 20000
                        },
                        {
                            name: '中块',
                            value: 30000
                        },
                        {
                            name: '大块',
                            value: 15000
                        },
                        {
                            name: '38块',
                            value: 25000
                        },
                        {
                            name: '籽煤',
                            value: 35000
                        },
                        {
                            name: '炼焦煤',
                            value: 40000
                        },
                        {
                            name: '原煤',
                            value: 16000
                        },
                    ]
                },
                khzd: {
                    rowNum: 7,
                    valueFormatter({value}) {
                        const reverseNumber = (value + '').split('').reverse()
                        let valueStr = ''

                        while (reverseNumber.length) {
                            const seg = reverseNumber.splice(0, 3).join('')
                            valueStr += seg
                            if (seg.length === 3) valueStr += ','
                        }

                        return valueStr.split('').reverse().join('') + "吨"
                    },
                    data: [
                        {
                            name: '洗精煤',
                            value: 16000
                        },
                        {
                            name: '末煤',
                            value: 21000
                        },
                        {
                            name: '中块',
                            value: 20000
                        },
                        {
                            name: '大块',
                            value: 25000
                        },
                        {
                            name: '38块',
                            value: 15000
                        },
                        {
                            name: '籽煤',
                            value: 18000
                        },
                        {
                            name: '炼焦煤',
                            value: 30000
                        },
                        {
                            name: '原煤',
                            value: 16000
                        },
                    ]
                }
            };
        },
        components: {
            //centerLeft1,
            //centerLeft2,
            //centerRight1,
            //centerRight2,
            //center,
            //bottomLeft,
            //bottomRight1,
            //bottomRight
        },
        beforeMount() {
            this.change()
        },
        mounted() {
            console.log(sessionStorage.getItem("hasLogin"))
            this.timeFn();
            this.cancelLoading();
            this.changeTiming();
        },
        methods: {
            timeFn() {
                setInterval(() => {
                    this.dateDay = formatTime(new Date(), 'HH: mm: ss');
                    this.dateYear = formatTime(new Date(), 'yyyy-MM-dd');
                    this.dateWeek = this.weekday[new Date().getDay()];
                }, 1000)
            },
            cancelLoading() {
                setTimeout(() => {
                    this.loading = false;
                }, 1200);
            },
            changeTiming() {
                setInterval(() => {
                    this.change();
                }, 65000);
            },
            compare(property) {
                return function (a, b) {
                    var value1 = a[property];
                    var value2 = b[property];
                    return value2 - value1;
                }
            },
            change() {
                var that = this
                this.$axios.get(this.$axios.defaults.baseURL).then(function (response) {
                    console.log(response.data.data)
                    var kucun = []
                    var xianzhi = 0
                    for (let a in response.data.data.gysCount) {
                        if (xianzhi <= 6) {
                            kucun.push({name: a, value: response.data.data.gysCount[a]})
                        }
                        xianzhi++
                    }
                    kucun.sort(that.compare('value'))
                    var gyzddata = []
                    var khzddata = []
                    for (let a in response.data.data.gszdWlCount) {
                        gyzddata.push({name: a, value: Number(response.data.data.gszdWlCount[a])})
                    }
                    for (let a in response.data.data.dfgzdWlCount) {
                        khzddata.push({name: a, value: Number(response.data.data.dfgzdWlCount[a])})
                    }
                    if (response.data.code == 200) {
                        that.shishishuju = [
                            {
                                number: {
                                    number: [Number(response.data.data.gszdDayCount)],
                                    toFixed: 1,
                                    content: "{nt}"
                                },
                                text: "今日实时公司自调数据"
                            },
                            {
                                number: {
                                    number: [Number(response.data.data.dfhzdDayCount)],
                                    toFixed: 1,
                                    content: "{nt}"
                                },
                                text: "今日实时客户自调数据"
                            },
                            {
                                number: {
                                    number: [Number(response.data.data.ddDayCount)],
                                    toFixed: 1,
                                    content: "{nt}"
                                },
                                text: "今日实时短倒数据"
                            },
                        ]
                        that.centerData = [
                            {
                                title: "今日公司自调采购已入场（车）",
                                number: {
                                    number: [Number(response.data.data.gszdYrc)],
                                    content: "{nt}",
                                    style: {
                                        fontSize: 50
                                    },
                                    formatter
                                },
                            },
                            {
                                title: "今日公司自调采购已出场（车）",
                                number: {
                                    number: [Number(response.data.data.gszdYcc)],
                                    content: "{nt}",
                                    style: {
                                        fontSize: 50
                                    },
                                    formatter
                                }
                            },
                            {
                                title: "今日公司自调采购未出场（车）",
                                number: {
                                    number: [Number(response.data.data.gszdWcc)],
                                    content: "{nt}",
                                    style: {
                                        fontSize: 50
                                    },
                                    formatter
                                }
                            },
                            {
                                title: "今日客户自调采购已入场（车）",
                                number: {
                                    number: [Number(response.data.data.dfhzdYrc)],
                                    content: "{nt}",
                                    style: {
                                        fontSize: 50
                                    },
                                    formatter
                                }
                            },
                            {
                                title: "今日客户自调采购已出场（车）",
                                number: {
                                    number: [Number(response.data.data.dfhzdYcc)],
                                    content: "{nt}",
                                    style: {
                                        fontSize: 50
                                    },
                                    formatter
                                }
                            },
                            {
                                title: "今日客户自调采购未出场（车）",
                                number: {
                                    number: [Number(response.data.data.dfhzdWcc)],
                                    content: "{nt}",
                                    style: {
                                        fontSize: 50
                                    },
                                    formatter
                                }
                            }
                        ]
                        that.gysData = {
                            data: kucun,
                            showValue: true,
                            unit: "吨",
                            colors: ['#e062ae', '#fb7293', '#e690d1', '#32c5e9', '#96bfff'],
                        }

                        that.bingyue = {
                            radius: '80%',
                            activeRadius: '85%',
                            lineWidth: 25,
                            activeTimeGap: 10000,
                            digitalFlopToFixed: 1,
                            digitalFlopUnit: "吨",
                            animationCurve: "easeOutCubic",
                            animationFrame: 100,
                            color: ['#10abe9', '#fb7293', '#96bfff'],
                            data: [
                                {
                                    name: '公司自调',
                                    value: Number(response.data.data.gszdMonthCount)
                                },
                                {
                                    name: '客户自调',
                                    value: Number(response.data.data.dfhzdMonthCount)
                                },
                            ],
                            digitalFlopStyle: {
                                fontSize: 15,
                            },
                            showOriginValue: true
                        }
                        that.bingnian = {
                            radius: '80%',
                            activeRadius: '85%',
                            lineWidth: 25,
                            activeTimeGap: 15000,
                            digitalFlopToFixed: 1,
                            digitalFlopUnit: "吨",
                            animationCurve: "easeOutCubic",
                            animationFrame: 100,
                            color: ['#10abe9', '#fb7293', '#96bfff'],
                            data: [
                                {
                                    name: '公司自调',
                                    value: Number(response.data.data.gszdYearCount)
                                },
                                {
                                    name: '客户自调',
                                    value: Number(response.data.data.dfhzdYearCount)
                                },
                            ],
                            digitalFlopStyle: {
                                fontSize: 15,
                            },
                            showOriginValue: true
                        }

                        that.gyzd = {
                            rowNum: 7,
                            valueFormatter({value}) {
                                return value + "吨"
                            },
                            data: gyzddata
                        }
                        that.khzd = {
                            rowNum: 7,
                            valueFormatter({value}) {
                                return value + "吨"
                            },
                            data: khzddata
                        }
                    } else {
                        console.log("数据获取失败")
                    }
                }).catch(function (error) {
                    console.log(error);
                });
            },
            denglu() {
                var that = this
                var params = new URLSearchParams();
                params.append('userName', this.userName);
                params.append('password', this.password);
                this.$axios.post(this.$axios.defaults.baseURL + "/login", params).then(function (response) {
                    console.log(response.data)
                    if (response.data.code == 0) {
                        that.hasLogin = 'login'
                        sessionStorage.setItem("hasLogin", "login")
                    } else {
                        alert(response.data.message)
                    }
                });
            }
        }
    };

    function formatter(number) {
        const numbers = number.toString().split('').reverse()
        const segs = []

        while (numbers.length) segs.push(numbers.splice(0, 3).join(''))

        return segs.join(',').split('').reverse().join('')
    }
</script>
<style lang="scss">
    @import "https://cdn.jsdelivr.net/npm/bootstrap@3.3.7/dist/css/bootstrap.min.css";
    @import '../assets/scss/index.scss';

    .dv-border-box-11-title {
        font-size: 14px;
    }

    #centreLeft1 {
        padding: 0.2rem;
        height: 5.125rem;
        min-width: 3.75rem;
        border-radius: 0.0625rem;

        .bg-color-black {
            height: 4.8125rem;
            border-radius: 0.125rem;
        }

        .text {
            color: #c3cbde;
        }

        .chart-box {
            margin-top: 0.2rem;
            width: 2.125rem;
            height: 2.125rem;

            .active-ring-name {
                padding-top: 0.125rem;
            }
        }

        .bottom-data {
            margin-top: 0.5rem;

            .item-box {
                float: left;
                position: relative;
                width: 32%;
                color: #d3d6dd;
                // 金币
                .coin {
                    position: absolute;
                    left: 0.1rem;
                    top: 0.2125rem;
                    font-size: 0.25rem;
                    color: #ffc107;
                }

                .colorYellow {
                    color: yellowgreen;
                }
            }
        }
    }
</style>
<style lang="scss" scoped>
    #center {
        display: flex;
        flex-direction: column;

        .up {
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-around;

            .item {
                border-radius: 0.0625rem;
                padding-top: 0.2rem;
                margin-top: 0.2rem;
                width: 32%;
                height: 2.3rem;
            }
        }

        .down {
            padding: 0.07rem 0.05rem;
            padding-bottom: 0;
            margin-top: 0.6rem;
            width: 100%;
            display: flex;
            height: 5.8rem;
            justify-content: space-between;

            .bg-color-black {
                border-radius: 0.0625rem;
            }

            .ranking {
                padding: 0.125rem;
                width: 59%;

                .dv-active-ring-chart {
                    .active-ring-info {
                        .active-ring-name {
                            width: 100px;
                            height: 30px;
                            color: #fff;
                            text-align: center;
                            vertical-align: middle;
                            text-overflow: ellipsis;
                            overflow: hidden;
                            white-space: nowrap;
                            line-height: 30px;
                        }
                    }
                }
            }

            .percent {
                width: 40%;
                display: flex;
                flex-wrap: wrap;

                .item {
                    width: 50%;
                    height: 1.5rem;

                    span {
                        margin-top: 0.0875rem;
                        display: flex;
                        justify-content: center;
                    }
                }

                .water {
                    width: 100%;
                }
            }
        }
    }
</style>
<style lang="scss">
    #centreRight2 {
        padding: 0.0625rem;
        height: 5rem;
        min-width: 3.75rem;
        border-radius: 0.0625rem;

        .bg-color-black {
            padding: 0.0625rem;
            height: 5.0625rem;
            border-radius: 0.125rem;
        }

        .text {
            color: #c3cbde;
        }

        .body-box {
            border-radius: 0.125rem;
            overflow: hidden;
        }
    }
</style>
<style lang="scss">
    #bottomRight {
        padding: 0.3rem 0.2rem 0;
        height: 6.5rem;
        min-width: 3.75rem;
        border-radius: 0.0625rem;

        .bg-color-black {
            height: 5.8rem;
            border-radius: 0.125rem;
        }

        .text {
            color: #c3cbde;
        }

        //下滑线动态
        .decoration2 {
            position: absolute;
            right: 0.125rem;
        }

        .chart-box {
            margin-top: 0.2rem;
            width: 2.125rem;
            height: 2.125rem;

            .active-ring-name {
                padding-top: 0.125rem;
            }
        }
    }
</style>